// Import libraries
import React, { useEffect, useContext, useCallback } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';


// Import navigation context
import NavigationContext from '../context/navigation';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    position: relative;
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - 15rem);
    overflow: hidden;

    & .content {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-rows: 1fr 6rem;
        align-items: center;
        justify-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);

        & .container {
            display: block;
            padding: 0 var(--padding-right) 0 var(--padding-left);
            width: 100%;
            max-width: var(--max-width);
        }

        & .inner h1 {
            font-size: calc(40px + (80 - 40) * ((100vw - 320px) / (2200 - 320)));
            line-height: 1.2;
            color: #fff;
            
            & .primary {
                color: var(--color-primary);
            }

            @media only screen and (min-width: 1600px) {
                font-size: 55px;
            }

            @media only screen and (min-width: 1900px) {
                font-size: 70px;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            outline: 0;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledVideo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    
    & .video {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;

        &[poster] {
            object-fit: cover;
        }
    }
`;

const StyledServices = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;
    margin: 0 auto;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 400px) {
        grid-template-columns: 1fr;
    }

    & .service {
        display: grid;
        grid-template-rows: 110px repeat(2, max-content);
        grid-row-gap: 1rem;
        justify-items: center;
        text-align: center;

        &-icon {

        }

        &__heading {
            font-size: 2rem;
            font-weight: 600;
        }

        &__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }
`;

const StyledOffer = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    
    & .overlay {
        display: grid;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
        padding: 10rem var(--padding-right) 10rem var(--padding-left);
    }

    & .content {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        grid-row-gap: 2rem;
        max-width: var(--max-width);
        text-align: center;
        margin: 0 auto;
        color: #fff;

        &__heading {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        &__paragraph {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }
`;

const StyledOurCustomers = styled.div`
    display: grid;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);

    & .content {
        display: grid;
        grid-template-rows: repeat(3, min-content);
        grid-row-gap: 3rem;
        max-width: var(--max-width);
        text-align: center;
        margin: 0 auto;

        &__heading {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        &__paragraph {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
            margin-top: -3rem;
        }

        &__link {
            justify-self: center;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;
        }

        &__link:link,
        &__link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &__link:hover,
        &__link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &__link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }

    & .customer-logos {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 5rem;
        grid-row-gap: 5rem;
        margin-top: 5rem;

        @media only screen and (max-width: 800px) {
            grid-template-columns: repeat(3, 1fr);
        }
        
        & .customer {
            display: grid;
            grid-row-gap: 1rem;
            align-items: center;
            justify-items: center;

            &__logo {
                width: 100%;
                max-width: 150px;
            }

            &__name {
                text-align: center;
                font-size: 1.2rem;
            }
        }
    }
`;

const StyledContactUs = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);
    grid-row-gap: 5rem;
    background-color: var(--color-background-2);

    @media only screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }

    & .content {
        grid-column: 1 / -1;

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }
    }
    
    & .contact {
        position: relative;
        display: grid;
        grid-template-rows: repeat(3, max-content);
        grid-row-gap: 5rem;
        justify-items: start;

        @media only screen and (max-width: 900px) {
            justify-items: center;
        }
        
        &-inner {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            justify-items: start;
        }

        &-link {
            display: grid;
            grid-template-columns: 5rem min-content 1fr;
            grid-column-gap: 2rem;
            align-items: center;

            & .icon {
                display: grid;
                align-items: center;
                justify-items: center;
                width: 5rem;
                height: 5rem;
                border: 3px solid #000;
                border-radius: 50%;
                font-size: 2.5rem;
                transition: border-color .25s ease;

                &--sms {
                    font-size: 3rem;
                }
            }

            &-text {
                font-size: 2.5rem;
                font-weight: 400;
                color: var(--color-primary);
            }
        }
        
        &-link:link,
        &-link:visited {
            color: #000;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
            font-size: 3rem;
            font-weight: 100;
            cursor: pointer;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;

            & .icon {
                border-color: var(--color-primary);
            }
        }

        &-link:active {
            text-decoration: none;
            color: #000;
        }

        &-link--small:link,
        &-link--small:visited {
            font-weight: var(--font-weight-body-p);
            font-size: var(--font-size-body-p);
        }
    }

    & .contact-form {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        grid-row-gap: 3rem;
        justify-items: center;
        width: 100%;

        &-text {
            font-size: 2.5rem;
            font-weight: 400;
            color: var(--color-primary);
        }

        & form {
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            grid-row-gap: 1rem;

            & .form-field-wrapper {
                position: relative;
                background-color: #fff;
                z-index: 1;

                & .icon {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translate(0, -50%);
                    z-index: -1;
                }

                & .placeholder {
                    position: absolute;
                    top: 50%;
                    left: 2rem;
                    transform: translateY(-50%);
                    color: #777676;
                    z-index: -1;
                }
            }

            & input::placeholder,
            & select::placeholder,
            & textarea::placeholder {
                color: #777676;
            }

            & input,
            & select,
            & textarea {
                font-family: inherit;
                font-size: inherit;
                border-radius: 0;
                border: 1px solid #ccc;
                padding: 1rem 2rem;
                outline: none;
                width: 100%;
            }

            & textarea {
                resize: none;
            }

            & select {
                -webkit-appearance: none;
                padding-right: 3rem;
                background-color: transparent;
            }

            & .button {
                display: flex;
                flex-direction: row;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                & span {
                    margin-right: 10px;
                }

                & .icon {
                    margin-top: 3px;
                }
            }

            & .button:hover,
            & .button:focus {
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            & .button:active {
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }
    }
`;

const StyledActionBanner = styled.div`
    & .content {
        display: grid;
        grid-template-columns: 75% 1fr;
        grid-row-gap: 1rem;
        padding: var(--padding-right);
        background-color: var(--color-secondary);
        color: #fff;
        border-bottom: 1px solid #fff;
        
        @media only screen and (max-width: 950px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 5rem;
        }

        & h1 {
            font-size: 5rem;
            font-weight: 100;
            line-height: var(--line-height-body-h1);
        }

        & h2 {
            font-size: 5rem;
            font-weight: 400;
            line-height: var(--line-height-body-h2);
            color: var(--color-primary);

            & .small-text {
                font-size: 1.5rem;
                font-weight: 100;
            }
        }

        &-links {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 30px;
            grid-column-gap: 30px;
            justify-items: center;

            @media only screen and (max-width: 950px) {
                grid-template-columns: repeat(2, max-content);
                grid-template-rows: 1fr;
                justify-items: start;
            }

            @media only screen and (max-width: 400px) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, min-content);
            }
        }

        &-link {
            /*grid-column: 2 / 3;
            grid-row: 1 / 3;
            align-self: center;
            justify-self: center;*/
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;
            text-decoration: none;

            & span {
                margin-right: 10px;
            }

            @media only screen and (max-width: 950px) {
                /* grid-column: 1 / -1;
                grid-row: 3 / 4;
                justify-self: start; */
            }
        }

        &-link:link,
        &-link:visited {
            color: currentColor;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: currentColor;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &-link:active {
            text-decoration: none;
            color: currentColor;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;


// Create component
const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    phoneNumber {
                        link
                        display
                    }
                    whatsappNumber {
                        link
                        display
                    }
                    email
                }
            }

            contactDetailsJson {
                email
                phone_number_display
                phone_number_link
                whatsapp_number_display
                whatsapp_number_link
                address
            }

            bannerVideo: allFile(filter: {relativePath: {eq: "banner/index/banner.mp4"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }

            bannerVideoThumbnail: file(relativePath: { eq: "banner/index/thumbnail.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            bannerImage: file(relativePath: { eq: "banner/index/banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }



            service1Icon: file(relativePath: { eq: "services/index/Bedrijfsadministratie.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service2Icon: file(relativePath: { eq: "services/index/Fiscaal-particulier.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service3Icon: file(relativePath: { eq: "services/index/Fiscaal-advies.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service4Icon: file(relativePath: { eq: "services/index/Salarisadministratie.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service5Icon: file(relativePath: { eq: "services/index/Begeleiding-startende-ondernemers.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service6Icon: file(relativePath: { eq: "services/index/Erf-en-schenkbelasting.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }



            customerLogoHotelZierikzee: file(relativePath: { eq: "customer-logos/hotel-zierikzee-logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoDBMLogoCMYK: file(relativePath: { eq: "customer-logos/DBMLogoCMYK.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoIThings: file(relativePath: { eq: "customer-logos/i-things-logo.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoUSDMonuta: file(relativePath: { eq: "customer-logos/LogoUSDMonuta.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoSouvenirShopZierikzee: file(relativePath: { eq: "customer-logos/souvenir-shop-zierikzee-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoMirasBrowbar: file(relativePath: { eq: "customer-logos/placeholder-female.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoJemmaWooldridge: file(relativePath: { eq: "customer-logos/placeholder-female.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoNancysThuiszorgVanAtotZ: file(relativePath: { eq: "customer-logos/placeholder-female.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoJortHardon: file(relativePath: { eq: "customer-logos/placeholder-male.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoActaZorg: file(relativePath: { eq: "customer-logos/Acta-zorg-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoBreakfastInRotterdam: file(relativePath: { eq: "customer-logos/Breakfast-in-rotterdam-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoDickenzAanHetStrand: file(relativePath: { eq: "customer-logos/Dickenz-aan-het-strand-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoHookedOnFishAndChips: file(relativePath: { eq: "customer-logos/Hooked-on-fish-and-chips-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoLNBWapeningsstaal: file(relativePath: { eq: "customer-logos/LNB-Wapeningsstaal-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoMSKGroep: file(relativePath: { eq: "customer-logos/MSK-Groep-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoSeekrz: file(relativePath: { eq: "customer-logos/Seekrz-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoStreetwear: file(relativePath: { eq: "customer-logos/Streetwear-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoVerdouwTotaalbouw: file(relativePath: { eq: "customer-logos/Verdouw-totaalbouw-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            customerLogoVlotr: file(relativePath: { eq: "customer-logos/Vlotr-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    // Array of customers with name and logo
    const ourCustomers = [
        {
            id: 1,
            name: 'Hotel Zierikzee',
            logo: data.customerLogoHotelZierikzee.childImageSharp.fluid
        },
        {
            id: 2,
            name: 'De Bruijne Media',
            logo: data.customerLogoDBMLogoCMYK.childImageSharp.fluid
        },
        {
            id: 3,
            name: 'i-Things',
            logo: data.customerLogoIThings.childImageSharp.fluid
        },
        {
            id: 4,
            name: 'Uitvaartverzorging Shouwen-Duiveland',
            logo: data.customerLogoUSDMonuta.childImageSharp.fluid
        },
        {
            id: 5,
            name: 'Acta Zorg',
            logo: data.customerLogoActaZorg.childImageSharp.fluid
        },
        {
            id: 6,
            name: 'Breakfast in Rotterdam',
            logo: data.customerLogoBreakfastInRotterdam.childImageSharp.fluid
        },
        {
            id: 7,
            name: 'Dickenz aan het Strand',
            logo: data.customerLogoDickenzAanHetStrand.childImageSharp.fluid
        },
        {
            id: 8,
            name: 'Hooked on Fish and Chips',
            logo: data.customerLogoHookedOnFishAndChips.childImageSharp.fluid
        },
        {
            id: 9,
            name: 'LNB Wapeningsstaal',
            logo: data.customerLogoLNBWapeningsstaal.childImageSharp.fluid
        },
        {
            id: 10,
            name: 'MSK Groep',
            logo: data.customerLogoMSKGroep.childImageSharp.fluid
        },
        {
            id: 11,
            name: 'Seekrz',
            logo: data.customerLogoSeekrz.childImageSharp.fluid
        },
        {
            id: 12,
            name: 'Streetwear',
            logo: data.customerLogoStreetwear.childImageSharp.fluid
        },
        {
            id: 13,
            name: 'Verdouw Totaalbouw',
            logo: data.customerLogoVerdouwTotaalbouw.childImageSharp.fluid
        },
        {
            id: 14,
            name: 'Vlotr',
            logo: data.customerLogoVlotr.childImageSharp.fluid
        }
    ];


    // Array of services
    const services = [
        {
            id: 1,
            title: 'Bedrijfsadministratie',
            description: 'Wilt u uw bedrijfsadministratie gedeeltelijk of geheel uit handen geven? Onze diensten kunnen wij aanpassen aan uw onderneming. Wij werken niet met een uurtje factuurtje mentaliteit, maar met vaste prijzen. Zo kunnen ZZP\'ers al bij ons terecht vanaf €60 p/m excl BTW.',
            icon: data.service1Icon.childImageSharp.fixed
        },
        {
            id: 2,
            title: 'Particuliere Administratie',
            description: 'Voor maar €75,- incl BTW verzorgen wij uw aangifte inkomstenbelasting. Wist u dat fiscale partners ook gratis zijn bij ons? Naast de aangifte inkomstenbelasting kunnen wij u ook voorzien van hypotheekadvies of pensioenadvies.',
            icon: data.service2Icon.childImageSharp.fixed
        },
        {
            id: 3,
            title: 'Advies',
            description: 'Als administratief kantoor kunnen wij zowel grote als kleine ondernemingen met concreet advies voorzien. Denk bijvoorbeeld aan begrotingen, prognoses, financieringsaanvragen, herstructureringen, verzekeringen, pensioen, en diverse complexe fiscale adviezen.',
            icon: data.service3Icon.childImageSharp.fixed
        },
        {
            id: 4,
            title: 'Salarisadministratie',
            description: 'Met onze gebruiksvriendelijke software kunnen wij uw salarisadministratie geheel uit handen nemen. Zo kunnen wij de salarisadministratie eenvoudig integreren in uw digitale boekhouding zodat alles altijd 24/7 bereikbaar is.',
            icon: data.service4Icon.childImageSharp.fixed
        },
        {
            id: 5,
            title: 'Begeleiding van Startende Ondernemers',
            description: 'Denkt u erover na om een onderneming te starten? Wij kunnen u begeleiden met het inschrijven bij de KvK, de rechtsvorm keuze, het opzetten van een correcte financiële administratie, verzekeringen, pensioen en eventuele investeringen.',
            icon: data.service5Icon.childImageSharp.fixed
        },
        {
            id: 6,
            title: 'Erf- en Schenkbelasting',
            description: 'Ons kantoor kan u adviseren op het gebied van erfbelasting en/of schenkbelasting. Zo zijn er diverse vrijstellingen waardoor schenken fiscaal heel aantrekkelijk kan worden. Daarnaast kunnen wij ook de aangifte hiervan verzorgen.',
            icon: data.service6Icon.childImageSharp.fixed
        }
    ];


    // Handle scrolling down to next section on page
    const handleScrollDown = () => {
        // Get header height difference between static header height and scollling header height and get banner height
        const headerHeightDifference = (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 15) - (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 10);
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        

        // Scroll to place underneath banner
        window.scrollTo({
            top: (bannerHeight + headerHeightDifference),
            left: 0,
            behavior: 'smooth'
        });
    };


    const { viewportSize } = useContext(NavigationContext);
    const positionBannerVideo = useCallback(() => {
        // Reset styles to default
        document.querySelector('.banner .video').style.width = '100%';
        document.querySelector('.banner .video').style.height = 'auto';
        document.querySelector('.banner .video').parentNode.style.left = '0px'

        // Get banner video height and banner height
        const bannerVideoHeight = document.querySelector('.banner .video').clientHeight;
        const bannerHeight = document.querySelector('.banner').clientHeight;

        // Check if banner video height is smaller than banner height
        if (bannerVideoHeight < bannerHeight) {
            // Stretch video's to fill banner height
            document.querySelector('.banner .video').style.width = 'auto';
            document.querySelector('.banner .video').style.height = bannerHeight + 'px';
            
            // Reposition video in banner in middle of screen based on viewport width and video width
            const bannerVideoWidth = document.querySelector('.banner .video').clientWidth;
            document.querySelector('.banner .video').parentNode.style.left = -((bannerVideoWidth - viewportSize.width) / 2) + 'px';
        } else {
            // Set video width to 100%
            document.querySelector('.banner .video').style.width = '100%';
            document.querySelector('.banner .video').style.height = 'auto';
            document.querySelector('.banner .video').parentNode.style.left = '0px'
        }
    }, [viewportSize.width]);

    
    useEffect(() => {
        setTimeout(() => {
            positionBannerVideo();
        }, 250);
    }, [positionBannerVideo]);


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO
                title="Home"
                description="Ons administratiekantoor werkt met de meest gebruiksvriendelijke en efficiënte administratie software zodat uw administratie altijd Up2Date is."
            />
            <Layout>
                <StyledBanner image={data.bannerVideoThumbnail} className="banner">
                    <StyledVideo>
                        <video
                            className="video"
                            src={data.bannerVideo.edges[0].node.publicURL}
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={data.bannerVideoThumbnail.childImageSharp.fluid.src}>
                        </video>
                    </StyledVideo>

                    <div className="content">
                        <div className="inner container">
                            <h1><span className="primary">Up2Date Administratie</span> werkt volgens het vaste prijs principe.</h1>
                        </div>

                        <div className="container">
                            <div className="scroll-down-button" role="button" tabIndex={0} onClick={handleScrollDown} onKeyDown={handleScrollDown}>
                                <FontAwesomeIcon icon={faArrowDown} className="icon" />
                            </div>
                        </div>
                    </div>
                </StyledBanner>

                {<StyledActionBanner>
                    <div className="content">
                        <div className="content-text">
                            <h1>Bekijk onze vacature</h1>
                            <h2>Financieel administratief medewerker 16-24 uur</h2>
                        </div>

                        <div className="content-links">
                            <Link to="/contact" className="content-link">
                                <span>Neem contact op</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>

                            <a href="https://www.up2date.nl/Vacature-juni-2022.pdf" target="_blank" rel="noopener noreferrer" className="content-link">
                                <span>Bekijk de vacature</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </a>
                        </div>
                    </div>
                </StyledActionBanner>}

                {<StyledActionBanner>
                    <div className="content">
                        <div className="content-text">
                            <h1>Bekijk onze vacature</h1>
                            <h2>Financieel administratief medewerker / Schoolverlater 16-40 uur</h2>
                        </div>

                        <div className="content-links">
                            <Link to="/contact" className="content-link">
                                <span>Neem contact op</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>

                            <a href="https://www.up2date.nl/Vacature-juli-2022.pdf" target="_blank" rel="noopener noreferrer" className="content-link">
                                <span>Bekijk de vacature</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </a>
                        </div>
                    </div>
                </StyledActionBanner>}

                <StyledServices>
                    {services.map(service => (
                        <div className="service" key={`service-${service.id}`}>
                            <Img fixed={service.icon} alt="" className="service__icon" />
                            <h3 className="service__heading">{service.title}</h3>
                            <p className="service__summary">{service.description}</p>
                        </div>
                    ))}
                </StyledServices>

                <StyledOffer image={data.bannerImage}>
                    <div className="overlay">
                        <div className="content">
                            <h2 className="content__heading">Wat bieden wij u?</h2>
                            <p className="content__paragraph">Up2Date Administratie is een vooruitstrevend kantoor op het gebied van automatisering. Weg met al het papierwerk, wij werken met gebruiksvriendelijke en effici&euml;nte software. Wij werken met een vast prijs principe waarbij transparantie en persoonlijk contact voorop staat.</p>
                        </div>
                    </div>
                </StyledOffer>

                <StyledOurCustomers>
                    <div className="content">
                        <h2 className="content__heading">De volgende ondernemers maken al gebruik van onze diensten</h2>
                        <p className="content__paragraph">Hieronder vindt u een aantal van onze klanten die al gebruik maken van onze diensten. Wilt u weten wat ze te zeggen hebben over ons?</p>

                        <Link to="/klanten" className="content__link">Wat zeggen onze klanten?</Link>
                    </div>

                    <div className="customer-logos">
                        {ourCustomers.map(customer => (
                            <div className="customer" key={customer.id}>
                                <Img fluid={customer.logo} alt={customer.name + " logo"} className="customer__logo" />
                                <span className="customer__name">{customer.name}</span>
                            </div>
                        ))}
                    </div>
                </StyledOurCustomers>

                <StyledContactUs>
                    <div className="content">
                        <h1>Wij staan voor u klaar</h1>
                    </div>

                    <div className="contact">
                        <div className="contact-inner">
                            <a href={`tel:${data.contactDetailsJson.phone_number_link}`} className="contact-link" aria-label="Bel ons">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                </div>
                                <p className="contact-link-text">Bel</p>
                                <span>{data.contactDetailsJson.phone_number_display}</span>
                            </a>
                        </div>

                        <div className="contact-inner">
                            <a href={`whatsapp:${data.contactDetailsJson.whatsapp_number_link}`} className="contact-link" aria-label="Whatsapp ons">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </div>
                                <p className="contact-link-text">Whatsapp</p>
                                <span>{data.contactDetailsJson.whatsapp_number_display}</span>
                            </a>
                        </div>

                        <div className="contact-inner">
                            <a href={`mailto:${data.contactDetailsJson.email}`} target="_blank" rel="noopener noreferrer" className="contact-link" aria-label="Email ons">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <p className="contact-link-text">Email</p>
                                <span>{data.contactDetailsJson.email}</span>
                            </a>
                        </div>
                    </div>
                    
                    <div className="contact-form">
                        <p className="contact-form-text">U kunt ook ons contactformulier invullen</p>
                        <form name="index-contact-form" action="/verstuurd?type=index-contact-form" method="POST" encType="application/x-www-form-urlencoded" data-netlify="true">
                            <input type="hidden" name="form-name" value="index-contact-form" />
                            <input type="text" name="name" placeholder="Uw naam" required />
                            <input type="text" name="phone-number" placeholder="Uw telefoonnummer" required />
                            <input type="text" name="email" placeholder="Uw email" required />
                            <textarea name="message" placeholder="Uw bericht" rows="6" required></textarea>
                            <button type="submit" className="button">
                                <span>Verzenden</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </button>
                        </form>
                    </div>
                </StyledContactUs>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default IndexPage;
